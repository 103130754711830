<template>
  <b-overlay
    :show="pending"
    bg-color="#f8f8f8"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0 justify-content-center">
        <div class="tbb-bg-image">
          <b-img
            :src="bgImage"
            alt="BeautiBook - Login"
          />
        </div>

        <!-- Brand logo-->
        <b-link class="brand-logo">
          <tbb-logotype />
          <h2 class="text-white tbb-logo mb-0">
            <span class="font-weight-light">Beauti</span>Book
          </h2>
        </b-link>
        <!-- /Brand logo-->
        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center"
        >
          <div class="mx-auto text-center mw-350">
            <div v-if="product && prices">
              <h2 class="text-white mb-2">
                {{ product.name }} plans
              </h2>

              <div class="row">
                <div
                  v-for="(price, index) in prices"
                  :key="index"
                  :class="[planColClass]"
                >
                  <div class="card popular text-center">
                    <div class="card-body">
                      <h3>Plan {{ price.metadata.plan || index }}</h3>
                      <p class="card-text">
                        {{ price.metadata.descr }}
                      </p>

                      <div class="plan-price mt-2 mb-2">
                        <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                        <span class="pricing-basic-value font-weight-bolder text-primary">{{ price.unit_amount / 100 }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                      </div>

                      <b-button @click="checkout(price)">
                        Select
                      </b-button>

                      <div v-if="price.metadata.trial">
                        <p class="card-text mt-0 mb-0">
                          or
                        </p>

                        <b-button
                          @click="startTrial(price)"
                        >
                          Start trial
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="card text-center">
                    <div class="card-body">
                      <h5>Discount code</h5>

                      <b-form
                        v-if="!pending && !promoCodeStore.response"
                        @submit.prevent="useStylistPromoCode(promoCodeInput)"
                      >
                        <b-form-group>
                          <b-form-input
                            id="promo-code"
                            v-model="promoCodeInput"
                            name="promo-code"
                          />

                          <p
                            v-if="promoCodeStore.error"
                            class="text-error"
                          >
                            Invalid code!
                          </p>
                        </b-form-group>

                        <b-button
                          type="submit"
                        >
                          Use
                        </b-button>
                      </b-form>

                      <div
                        v-if="promoCodeStore.response"
                      >
                        <span v-if="promoCodeStore.response.amountOff">-{{ promoCodeStore.response.amountOff }} USD</span>
                        <span v-if="promoCodeStore.response.percentOff">-{{ promoCodeStore.response.percentOff }}%</span>
                        applied!
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCol,
  BImg,
  BRow,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { loadStripe } from '@stripe/stripe-js'

import { SUBSCRIPTION_RETURN_PATH } from '@/router/routes/routes-path'
import TbbBg from '../../assets/images/pages/tbb-bg.jpg'

export default {
  components: {
    BButton,
    BImg,
    BCol,
    BRow,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      bgImage: TbbBg,
      inProgress: true,
      promoCodeInput: null,
    }
  },
  computed: {
    ...mapState('SubscriptionStoreModule', {
      product: state => state.planData.response.product,
      prices: state => state.planData.response.prices.map(price => {
        if (state.promoCode.response?.percentOff) {
          const percentOff = state.promoCode.response?.percentOff
          const amountOff = ((price.unit_amount * percentOff) / 100)

          return {
            ...price,
            unit_amount: price.unit_amount - amountOff,
          }
        }

        if (state.promoCode.response?.amountOff) {
          const amountOff = state.promoCode.response?.amountOff

          return {
            ...price,
            unit_amount: price.unit_amount - amountOff,
          }
        }

        return price
      }),
      pending: state => state.planData.pending
        || state.checkout.pending
        || state.subscription.pending
        || state.promoCode.pending,
      planColClass: state => {
        const len = 12 / (state.planData.response?.prices?.length || 1)

        return `col-md-${len}`
      },
      promoCodeStore: state => state.promoCode,
    }),
  },
  mounted() {
    this.fetchStylistPlans()

    this.checkStylistSubscriptionStatus()
      .then(response => {
        const { subscription } = response.data

        if (subscription && ['trialing', 'active'].indexOf(subscription.status) >= 0) {
          this.$router.push({ path: `${SUBSCRIPTION_RETURN_PATH}/success` })
        }
      })
  },
  methods: {
    ...mapActions('SubscriptionStoreModule', [
      'fetchStylistPlans',
      'checkoutStylistPlan',
      'startStylistTrial',
    ]),
    ...mapActions('SubscriptionStoreModule', [
      'checkStylistSubscriptionStatus',
      'useStylistPromoCode',
    ]),

    async checkout(price) {
      // todo: error handling
      const returnUrl = new URL(SUBSCRIPTION_RETURN_PATH, window.location.origin)
      const response = await this.checkoutStylistPlan({
        priceId: price.id,
        returnUrl: returnUrl.toString(),
        promoCode: this.promoCodeStore.code,
      })
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)

      stripe.redirectToCheckout({ sessionId: response.data.id })
    },

    async startTrial(price) {
      const priceId = price.id
      await this.startStylistTrial(priceId)

      this.$router.push({ path: `${SUBSCRIPTION_RETURN_PATH}/success` })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import 'src/assets/scss/elements/login-page.scss';
</style>
